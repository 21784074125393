const targetRatio = 4 / 3;

export default async function cropImage(src) {
  const img = document.createElement('img');
  img.src = src;

  await new Promise(resolve => {
    img.onload = () => resolve();
  });

  const canvas = document.createElement('canvas');
  canvas.width = img.height * targetRatio;
  canvas.height = img.height;

  const offset = {
    x: (canvas.width - img.width) / 2,
    y: (canvas.height - img.height) / 2,
  };

  const ctx = canvas.getContext('2d');
  ctx.drawImage(img, offset.x, offset.y, img.width, img.height);

  return canvas.toDataURL('image/jpeg');
}
