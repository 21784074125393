import React from 'react';
import tickets from '../../services/tickets';
import styled, { createGlobalStyle } from 'styled-components';
import Ticket from './Ticket';

const GlobalStyle = createGlobalStyle`
  @media print {
    body {
        width: 21cm;
        height: 29.7cm;
        margin: 60mm 50mm 60mm;
        /* change the margins as you want them to be. */
   }
}
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export default () => {
  const [loading, setLoading] = React.useState(true);
  const [ticketList, setTicketList] = React.useState([]);

  const updateVotes = () => {
    tickets.list().then(votes => {
      setTicketList(votes);
      setLoading(false);
    });
  };

  React.useEffect(() => updateVotes(), []);

  if (loading) {
    return <div>Carregando...</div>;
  }

  const codes = ticketList
    .filter(t => t.valid && !t.finished && !t.categories)
    .map(t => t.id);

  return (
    <Wrapper>
      <GlobalStyle />
      {codes.map(code => (
        <Ticket key={code} code={code} />
      ))}
    </Wrapper>
  );
};
