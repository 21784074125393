import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: absolute;
  bottom: 4rem;

  align-self: center;

  color: #fff;

  font-weight: 900;

  width: 450px;
  text-align: center;
  font-size: 36px;

  line-height: 1.2em;
  text-shadow: 0 0 8px #000;
`;

const Image = styled.img`
  margin-bottom: 12px;
`;

export default () => {
  return (
    <Wrapper>
      <Image src={require('./assets/pentagram.svg')} width="74" height="74" />
      <div>Fique sobre o pentragrama e aguarde 13 segundos</div>
    </Wrapper>
  );
};
