import styled, { css } from 'styled-components';
import { prop, ifProp } from 'styled-tools';
import palette from '../config/palette';

export default styled.p`
  color: ${palette.lightPurple};

  font-size: ${prop('size', 18)}px;
  font-weight: ${prop('weight', 'normal')};

  text-align: center;

  ${ifProp(
    'noMargin',
    css`
      margin: 0;
    `,
  )}
`;
