import React from 'react';
import styled from 'styled-components';
import palette from '../../../config/palette';
import Text from '../../../components/Text';

const Wrapper = styled.div`
  background: ${palette.darkPurple};
  color: ${palette.lightPurple};

  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 12px 12px 16px;

  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.6);

  z-index: 10;
`;

const Title = styled.h2`
  color: ${palette.white};
  font-size: 32px;
  text-transform: uppercase;
  font-weight: 900;
  margin-bottom: 8px;
`;

export default ({ category }) => {
  return (
    <Wrapper>
      <Title>{category.name}</Title>
      <Text size={20} noMargin>
        {category.description}
      </Text>
    </Wrapper>
  );
};
