import { database } from './firebase';

function watchConfig(fn, context) {
  database.ref(`config`).on('value', fn, context);
  return () => database.ref(`config`).off('value', fn, context);
}

function setSpooky(spooky) {
  database.ref(`config/spooky`).set(spooky);
}

export default {
  watchConfig,
  setSpooky,
};
