import React from 'react';
import { Card, CardBody, CardSubtitle, CardTitle } from 'reactstrap';
import styled, { css } from 'styled-components';
import { ifProp } from 'styled-tools';
import FirebaseImage from '../../../components/FirebaseImage';
import palette from '../../../config/palette';

const ImageWrapper = styled.div`
  padding-bottom: 75%;
  position: relative;

  & > * {
    position: absolute;
    top: 0;
  }
`;

const StyledCard = styled(Card)`
  && {
    border: 0;
    overflow: hidden;

    border-radius: 8px;

    ${ifProp(
      'selected',
      css`
        box-shadow: 0 0 0 6px ${palette.green};
      `,
    )}
  }
`;

const StyledCardBody = styled(CardBody)`
  && {
    padding: 12px;
  }
`;

const StyledCardTitle = styled(CardTitle)`
  && {
    font-weight: bold;
    color: ${palette.darkPurple};
    margin-bottom: 4px;

    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;

const StyledCardSubtitle = styled(CardSubtitle)`
  &&& {
    margin-bottom: 0;

    color: ${palette.darkPurple};

    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;

export default ({ entry, selected, onSelect }) => {
  return (
    <StyledCard onClick={onSelect} selected={selected}>
      <ImageWrapper>
        <FirebaseImage path={entry.imagePath} width="100%" height="100%" />
      </ImageWrapper>
      <StyledCardBody>
        <StyledCardTitle>{entry.costume}</StyledCardTitle>
        <StyledCardSubtitle>{entry.name}</StyledCardSubtitle>
      </StyledCardBody>
    </StyledCard>
  );
};
