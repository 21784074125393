import React from 'react';
import { Table } from 'reactstrap';
import FirebaseImage from '../../../components/FirebaseImage';

const Entry = ({ entry, onEdit, onDelete }) => {
  return (
    <tr>
      <td>
        {entry.imagePath && <FirebaseImage width="98" path={entry.imagePath} />}
      </td>
      <td>{entry.name}</td>
      <td>{entry.costume}</td>
      <td>{entry.finished ? 'finished' : 'not finished'}</td>
      <td>{entry.createdAt}</td>
      <td>
        <button onClickCapture={onEdit}>Edit</button>{' '}
        <button onClickCapture={onDelete}>Remove</button>
      </td>
    </tr>
  );
};

export default ({ entries, onEdit, onDelete }) => {
  return (
    <Table responsive>
      <thead>
        <tr>
          <th>Foto</th>
          <th>Nome</th>
          <th>Fantasia</th>
          <th>Finalizado</th>
          <th>Data de Cadastro</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {entries.map(entry => (
          <Entry
            key={entry.id}
            entry={entry}
            onEdit={() => onEdit(entry)}
            onDelete={() => onDelete(entry)}
          />
        ))}
      </tbody>
    </Table>
  );
};
