import React from 'react';
import styled from 'styled-components';
import Button from '../../components/Button';
import palette from '../../config/palette';
import remote from '../../services/remote';

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;

  position: relative;
  display: flex;
  flex-direction: column;

  background: ${palette.darkPurple};
  color: white;
`;

export default () => {
  const [config, setConfig] = React.useState({});

  React.useEffect(() => {
    return remote.watchConfig(snapshot => {
      const value = snapshot.val();
      setConfig(value);
    });
  }, []);

  const onClickToggle = () => {
    remote.setSpooky(!config.spooky);
  };

  if (!config) {
    return null;
  }

  return (
    <Wrapper>
      <p>{config.spooky ? 'Spooky!' : 'Not spooky :c'}</p>
      <Button onClick={onClickToggle}>Toggle</Button>
    </Wrapper>
  );
};
