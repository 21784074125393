import React from 'react';
import { Button, Form, FormGroup, Input, Label } from 'reactstrap';

export default ({ entry, onSave, onCancel }) => {
  const [fields, setFields] = React.useState({});

  React.useEffect(() => {
    const { name, costume, finished } = entry;
    setFields({ name, costume, finished });
  }, [entry]);

  const onNameChange = event => {
    const name = event.target.value;
    setFields({ ...fields, name });
  };

  const onCostumeChange = event => {
    const costume = event.target.value;
    setFields({ ...fields, costume });
  };

  const onFinishedChange = event => {
    const finished = event.target.checked;
    console.log(finished);
    setFields({ ...fields, finished });
  };

  const onSubmit = event => {
    onSave({
      ...entry,
      ...fields,
    });
    event.preventDefault();
  };

  return (
    <Form onSubmitCapture={onSubmit}>
      <FormGroup>
        <Label for="name">Nome</Label>
        <Input
          type="name"
          name="name"
          id="name"
          value={fields.name}
          onChange={onNameChange}
        />
      </FormGroup>
      <FormGroup>
        <Label for="costume">Fantasia</Label>
        <Input
          type="costume"
          name="costume"
          id="costume"
          value={fields.costume}
          onChange={onCostumeChange}
        />
      </FormGroup>
      <FormGroup check>
        <Label check>
          <Input
            type="checkbox"
            checked={fields.finished}
            onChange={onFinishedChange}
          />{' '}
          Finalizado
        </Label>
      </FormGroup>
      <Button type="submit">Salvar</Button>
      <Button onClickCapture={onCancel}>Cancelar</Button>
    </Form>
  );
};
