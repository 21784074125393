import _, { orderBy } from 'lodash';
import React from 'react';
import { Pie, PieChart, ResponsiveContainer, Tooltip, Cell } from 'recharts';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const ChartWrapper = styled.div`
  width: 33%;
  text-align: center;
`;

const COLORS = [
  '#003f5c',
  '#2f4b7c',
  '#665191',
  '#a05195',
  '#d45087',
  '#f95d6a',
  '#ff7c43',
  '#ffa600',
].reverse();

export default ({ data }) => {
  const charts = React.useMemo(() => {
    const entryMap = _.keyBy(data.entries, 'id');
    console.log(entryMap);

    const charts = [];
    const resultMap = {};

    for (const category of data.categories) {
      const voteMap = {};

      for (const ticket of data.tickets) {
        if (!ticket.categories) {
          continue;
        }

        const vote = ticket.categories[category.id];
        if (!vote) {
          continue;
        }

        const previousCount = voteMap[vote] || 0;
        voteMap[vote] = previousCount + 1;
      }

      const chartData = [];
      for (const [entryId, votes] of Object.entries(voteMap)) {
        const entry = entryMap[entryId];
        chartData.push({
          name: `${entry.name} - ${entry.costume}`,
          value: votes,
        });

        if (!resultMap[entryId]) {
          resultMap[entryId] = {};
        }

        resultMap[entryId][category.id] = votes;
      }

      charts.push({
        title: category.name,
        data: orderBy(chartData, 'value', 'desc'),
      });
    }

    return charts;
  }, [data]);

  return (
    <Wrapper>
      {charts.map(chart => {
        return (
          <ChartWrapper>
            <h2>{chart.title}</h2>
            <ResponsiveContainer height={400} width="100%">
              <PieChart>
                <Pie
                  dataKey="value"
                  isAnimationActive={false}
                  data={chart.data}
                  cx={160}
                  cy={160}
                  outerRadius={160}
                  fill="#8884d8"
                >
                  {chart.data.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[Math.min(index, COLORS.length - 1)]}
                    />
                  ))}
                </Pie>
                <Tooltip />
              </PieChart>
            </ResponsiveContainer>
          </ChartWrapper>
        );
      })}
    </Wrapper>
  );
};
