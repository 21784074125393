import React from 'react';
import styled from 'styled-components';
import { Form, FormGroup, Input, Label, Container } from 'reactstrap';
import palette from '../../../config/palette';
import Button from '../../../components/Button';
import SpookyIcon from '../../../components/SpookyIcon';

const Wrapper = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;

  && {
    padding: 64px;
  }
`;

const Logo = styled.img`
  width: 100%;
  max-width: 320px;
  margin-bottom: 42px;
`;

const StyledLabel = styled(Label)`
  color: ${palette.lightPurple};

  font-size: 18px;
  font-weight: bold;

  && {
    text-align: center;
  }
`;

const StyledInput = styled(Input)`
  && {
    width: 200px;
    height: 1.2em;

    padding: 0;
    margin: 0 auto 12px;

    border: none;
    border-bottom: 2px solid ${palette.orange};

    color: ${palette.white};
    background: none;

    font-size: 48px;
    font-weight: bold;
    text-align: center;

    outline: none;

    :focus {
      outline-style: none;
      box-shadow: none;

      background: none;
      color: ${palette.white};
      border-bottom: 2px solid ${palette.orange};
    }
  }
`;

const StyledFormGroup = styled(FormGroup)`
  && {
    margin-bottom: 36px;
  }
`;

const StyledForm = styled(Form)`
  text-align: center;
`;

const Error = styled.div`
  font-weight: bold;
  color: ${palette.orange};
`;

export default ({ error, onLogin }) => {
  const [code, setCode] = React.useState();

  const onCodeChange = event => {
    const code = event.target.value;
    setCode(code);
  };

  const onSubmit = event => {
    event.preventDefault();
    onLogin(code);
    setCode('');
  };

  const codeIsValid = /^\d{6}$/.test(code);

  return (
    <Wrapper>
      <Logo src={require('./assets/logo.png')} />
      <StyledForm onSubmitCapture={onSubmit} noValidate="true">
        <StyledFormGroup>
          <StyledLabel for="code">
            Para começar, insira o código no campo abaixo:
          </StyledLabel>

          <StyledInput
            type="code"
            name="code"
            id="code"
            pattern="\d*"
            size={6}
            maxlength={6}
            value={code}
            onChange={onCodeChange}
            autocomplete="off"
          />
          {!!error ? <Error>{error}</Error> : null}
        </StyledFormGroup>
        <Button size={24} type="submit" disabled={!codeIsValid}>
          <SpookyIcon /> Começar
        </Button>
      </StyledForm>
    </Wrapper>
  );
};
