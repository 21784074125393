import React from 'react';
import categories from '../../../services/categories';
import entries from '../../../services/entries';
import EntryList from './EntryList';
import Header from './Header';
import Footer from './Footer';
import _ from 'lodash';
import styled from 'styled-components';
import { animateScroll } from 'react-scroll';

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;

  display: flex;
  flex-direction: column;
`;

const ENTRY_SCROLL_ID = 'entryScroll';

export default ({ onVote, onFinish }) => {
  const [categoryList, setCategoryList] = React.useState([]);
  const [entryList, setEntryList] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  const [categoryIndex, setCategoryIndex] = React.useState(0);
  const [selectedEntry, setSelectedEntry] = React.useState();

  const updateData = () => {
    categories
      .list()
      .then(categories => {
        setCategoryList(categories);
      })
      .then(() => entries.listEligible())
      .then(entries => {
        entries = _.sortBy(entries, 'name');
        setEntryList(entries);
        setLoading(false);
      });
  };

  React.useEffect(() => updateData(), []);

  if (loading) {
    return <div>Carregando...</div>;
  }

  const category = categoryList[categoryIndex];

  const onSelectEntry = entry => {
    setSelectedEntry(entry);
  };

  const onConfirmVote = () => {
    onVote({
      entry: selectedEntry,
      category,
    });

    setSelectedEntry(undefined);

    animateScroll.scrollToTop({
      duration: 0,
      containerId: ENTRY_SCROLL_ID,
    });

    const nextCategoryIndex = categoryIndex + 1;
    if (nextCategoryIndex < categoryList.length) {
      setCategoryIndex(categoryIndex + 1);
    } else {
      onFinish();
    }
  };

  return (
    <Wrapper>
      <Header category={category} />
      <EntryList
        {...{ entries: entryList, selectedEntry, onSelect: onSelectEntry }}
        id={ENTRY_SCROLL_ID}
      />
      <Footer onConfirm={onConfirmVote} disabled={!selectedEntry} />
    </Wrapper>
  );
};
