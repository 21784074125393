import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: absolute;
  top: 4rem;

  align-self: center;

  color: #fff;

  font-size: 300px;
  font-weight: 900;

  text-shadow: 0 0 8px #000;
`;

export default ({ current }) => {
  return <Wrapper>{current}</Wrapper>;
};
