import React from 'react';
import Sound from 'react-sound';

const musics = [
  '/assets/music/Bioshock Soundtrack - 11 This Is Where They Sleep-nE_oDn5ibko.mp3',
  '/assets/music/C418 - 13-ExhKqGIaAyI.mp3',
  '/assets/music/Creepy Video Game Music 8 - Who Are You (Who the F_k Are You Vers.)-75x3J4SPERQ.mp3',
  '/assets/music/Diablo 1 - Catacombs music HD-jrK90XJfqRs.mp3',
  '/assets/music/Half Life 2 Soundtrack_Probably Not A Problem-wgsZHcPMm64.mp3',
  "/assets/music/Majora's Mask - 29  Song of Healing-VlTwP5eRzeY.mp3",
  '/assets/music/Meu cãozinho Xuxo de trás pra frente (backmasking)-tlRI0_3DrgU.mp3',
  "/assets/music/Oddworld - Abe's Oddyse - Free Fire Zone-9DE_DaPZmFo.mp3",
  '/assets/music/pokemon-fire-red-and-leaf-green-lavender-town-music.mp3',
  '/assets/music/Pokemon Fire Red and Leaf Green - Lavender Town music-WzW9Xf08GaI.mp3',
  '/assets/music/Pokémon Platinum - Distortion World (Remastered) _ Thank You for 3k!-IObwU3RBS9c.mp3',
  '/assets/music/Resident Evil 2 Soundtrack - The Basement Of Police Station [R.P.D.]-tgzKbctSuIg.mp3',
  '/assets/music/Super Mario World - Ghost House Orchestral Cover-nGPxmI2Ld9k.mp3',
  '/assets/music/Super Paper Mario Music - River Twygz Bed-WsuTC1yGqng.mp3',
];

export default () => {
  const [index, setIndex] = React.useState(0);
  const url = musics[index % musics.length];

  const onFinishedPlaying = () => {
    setIndex(index + 1);
  };

  const onError = () => {
    setTimeout(() => {
      setIndex(index + 1);
    }, 15000);
  };

  // return null;
  return (
    <Sound
      url={url}
      playStatus={Sound.status.PLAYING}
      onFinishedPlaying={onFinishedPlaying}
      onError={onError}
      volume={10}
    />
  );
};
