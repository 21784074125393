import firebase from 'firebase/app';
import 'firebase/storage';
import 'firebase/database';

const firebaseConfig = {
  apiKey: 'AIzaSyDZ1bAQQfdYUBI8tnQsBqYRqawUWaruQG8',
  authDomain: 'halloween-c6e43.firebaseapp.com',
  databaseURL: 'https://halloween-c6e43.firebaseio.com',
  projectId: 'halloween-c6e43',
  storageBucket: 'halloween-c6e43.appspot.com',
  messagingSenderId: '386960041822',
  appId: '1:386960041822:web:e1bb8b45cec7b43aba51cf',
};

const app = firebase.initializeApp(firebaseConfig);

export const storage = app.storage();

export const database = app.database();
