import React from 'react';
import styled, { css } from 'styled-components';
import palette from '../config/palette';
import { withProp, ifProp, prop, ifNotProp } from 'styled-tools';

const Wrapper = styled.button`
  padding: ${withProp('size', s => 0.3 * s)}px
    ${withProp('size', s => 1.8 * s)}px;

  border: 5px solid ${withProp('colors', c => c.dark)};
  border-radius: ${withProp('size', s => 2 * s)}px;

  background: ${withProp('colors', c => c.medium)};
  color: ${withProp('colors', c => c.darker)};
  outline: none;

  box-shadow: 0 ${withProp('size', s => 0.25 * s)}px 0 0
    ${withProp('colors', c => c.dark)};

  font-size: ${prop('size')}px;
  font-weight: bold;

  line-height: 1em;

  cursor: pointer;

  svg {
    margin-right: 0.4em;
  }

  ${ifNotProp(
    'disabled',
    css`
      :hover {
        color: ${withProp('colors', c => c.dark)};
        background: ${withProp('colors', c => c.light)};
        text-decoration: none;
      }

      :focus {
        outline: none;
      }

      :active {
        transform: translateY(${withProp('size', s => 0.15 * s)}px);
        box-shadow: 0 ${withProp('size', s => 0.1 * s)}px 0 0
          ${withProp('colors', c => c.dark)};
        outline: none;
      }
    `,
  )}

  ${ifProp(
    'disabled',
    css`
      opacity: 0.2;
    `,
  )}
`;

const colors = {
  orange: {
    light: palette.lighOrange,
    medium: palette.orange,
    dark: palette.darkOrange,
    darker: palette.darkerOrange,
  },
  green: {
    light: palette.lighGreen,
    medium: palette.green,
    dark: palette.darkGreen,
    darker: palette.darkerGreen,
  },
};

export default ({ children, color = 'orange', size = 32, ...props }) => {
  return (
    <Wrapper href="" colors={colors[color]} size={size} {...props}>
      {children}
    </Wrapper>
  );
};
