import React from 'react';
import { useHistory, useParams } from 'react-router';
import styled from 'styled-components';
import palette from '../../config/palette';
import tickets from '../../services/tickets';
import Finished from './Finished';
import Login from './Login';
import Voting from './Voting';

const Wrapper = styled.div`
  width: 100vw;
  min-height: 100vh;

  position: relative;
  display: flex;
  flex-direction: column;

  background: ${palette.darkPurple};
`;

export default () => {
  const { code } = useParams();
  const history = useHistory();

  const [ticket, setTicket] = React.useState();
  const [error, setError] = React.useState();
  const [finished, setFinished] = React.useState(false);

  React.useEffect(() => {
    if (code) {
      doLogin(code).catch(e => {
        alert(JSON.stringify(e));
        history.push('/vote');
      });
    }
  }, [code, history]);

  const doLogin = async code => {
    const ticket = await tickets.getById(code);

    if (ticket.finished) {
      setFinished(true);
    } else {
      setTicket(ticket);
    }
  };

  const onLogin = async code => {
    try {
      await doLogin(code);
      setError(undefined);
    } catch (e) {
      setError('Boo! O código parece estar errado...');
    }
  };

  const onLogout = () => {
    setTicket(undefined);
    setFinished(false);
    history.push('/vote');
  };

  const onFinish = () => {
    tickets.update(ticket.id, {
      finished: true,
    });

    setTicket(undefined);
    setFinished(true);
  };

  const onVote = ({ entry, category }) => {
    tickets.update(ticket.id, {
      [`categories/${category.id}`]: entry.id,
    });
  };

  let Step;
  if (finished) {
    Step = Finished;
  } else if (ticket) {
    Step = Voting;
  } else if (code) {
    Step = () => null;
  } else {
    Step = Login;
  }

  return (
    <Wrapper>
      <Step
        {...{
          error,
          ticket,
          onLogin,
          onLogout,
          onFinish,
          onVote,
        }}
      />
    </Wrapper>
  );
};
