import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import Entries from './Entries';
import Tickets from './Tickets';
import Results from './Results';

export default () => (
  <BrowserRouter>
    <Switch>
      <Route path="/manage/entries" component={Entries} />
      <Route path="/manage/tickets" component={Tickets} />
      <Route path="/manage/results" component={Results} />
      <Route
        path="/manage"
        exact
        render={() => <Redirect to="/manage/entries" />}
      />
    </Switch>
  </BrowserRouter>
);
