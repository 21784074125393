import { database } from './firebase';
import _ from 'lodash';

async function list() {
  const snapshot = await database.ref(`categories`).once('value');

  const values = snapshot.val();
  if (!values) {
    return [];
  }

  const list = Object.entries(values)
    .map(([id, entry]) => ({
      ...entry,
      id,
    }))
    .filter(entry => !entry.deleted);

  return _.sortBy(list, 'order');
}
export default {
  list,
};
