import React from 'react';
import { Container } from 'reactstrap';
import styled from 'styled-components';
import Button from '../../../components/Button';
import SpookyIcon from '../../../components/SpookyIcon';
import palette from '../../../config/palette';

const Wrapper = styled(Container)`
  flex: 1;

  display: flex;
  flex-direction: column;
  align-items: center;

  && {
    padding: 64px;
  }
`;

const Logo = styled.img`
  max-width: 100%;
  margin-bottom: 42px;
`;

const Title = styled.div`
  margin-bottom: 48px;

  color: ${palette.lightPurple};

  font-size: 18px;
  font-weight: bold;

  text-align: center;
`;

const StyledButton = styled(Button)`
  justify-self: flex-end;
`;

export default ({ onLogout }) => {
  const onClickLogout = event => {
    event.preventDefault();
    onLogout();
  };

  return (
    <Wrapper>
      <Logo src={require('./assets/done.png')} />
      <Title for="code">Agora é só esperar o resultado</Title>
      <StyledButton size={24} onClick={onClickLogout}>
        <SpookyIcon /> Início
      </StyledButton>
    </Wrapper>
  );
};
