import React from 'react';
import Entry from './Entry';
import styled from 'styled-components';

const Wrapper = styled.div`
  flex: 1;

  padding: 1em;

  overflow-x: hidden;
  overflow-y: scroll;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;

  margin: -12px 0;
`;

const EntryWrapper = styled.div`
  width: 50%;
  padding: 12px;
`;

export default ({ entries, selectedEntry, onSelect, ...props }) => {
  return (
    <Wrapper {...props}>
      {entries.map(entry => (
        <EntryWrapper>
          <Entry
            entry={entry}
            selected={entry === selectedEntry}
            onSelect={() => onSelect(entry)}
          />
        </EntryWrapper>
      ))}
    </Wrapper>
  );
};
