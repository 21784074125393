import React from 'react';
import { Table } from 'reactstrap';

const Vote = ({ vote, categories, entryMap, onDelete }) => {
  let status = '';
  if (vote.finished) {
    status = 'Finished';
  } else if (vote.categories) {
    status = 'Parcial';
  }

  const ccc = vote.categories || {};

  return (
    <tr>
      <td>{vote.id}</td>
      <td>{status}</td>
      {categories.map(c => {
        const entry = entryMap[ccc[c.id]];
        return <td>{entry && entry.name}</td>;
      })}
      <td>
        <button onClickCapture={onDelete}>Remove</button>
      </td>
    </tr>
  );
};

export default ({ votes, categories, entryMap, onDelete }) => {
  return (
    <Table responsive>
      <thead>
        <tr>
          <th>Código</th>
          <th>Status</th>
          {categories.map(c => {
            return <th>{c.name}</th>;
          })}
          <th></th>
        </tr>
      </thead>
      <tbody>
        {votes.map(vote => (
          <Vote
            key={vote.id}
            categories={categories}
            entryMap={entryMap}
            vote={vote}
            onDelete={() => onDelete(vote)}
          />
        ))}
      </tbody>
    </Table>
  );
};
