import React from 'react';
import entries from '../../../services/entries';
import Form from './Form';
import List from './List';

export default () => {
  const [loading, setLoading] = React.useState(true);
  const [entryList, setEntryList] = React.useState([]);
  const [currentEntry, setCurrentEntry] = React.useState();

  const updateEntries = () => {
    entries.list().then(entries => {
      setEntryList(entries);
      setLoading(false);
    });
  };

  React.useEffect(() => updateEntries(), []);

  const onEdit = entry => setCurrentEntry(entry);

  const onDelete = entry => {
    if (
      window.confirm(`Confirma remoção de "${entry.name} - ${entry.costume}"?`)
    ) {
      onSave({
        ...entry,
        deleted: true,
      });
    }
  };

  const onCancel = () => setCurrentEntry(undefined);
  const onSave = ({ id, ...data }) => {
    setCurrentEntry(undefined);

    setLoading(true);
    entries.update(id, data).then(() => updateEntries());
  };

  if (loading) {
    return <div>Carregando...</div>;
  }

  return (
    <div>
      {currentEntry && <Form entry={currentEntry} {...{ onSave, onCancel }} />}
      {!currentEntry && <List entries={entryList} {...{ onEdit, onDelete }} />}
    </div>
  );
};
