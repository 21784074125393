import _ from 'lodash';

import React from 'react';
import { Table } from 'reactstrap';

const Row = ({ entry, categories }) => {
  return (
    <tr>
      <td>{entry.name}</td>
      <td>{entry.costume}</td>
      {categories.map(category => (
        <td key={category.id}>{entry.categories[category.id] || 0}</td>
      ))}
    </tr>
  );
};

export default ({ data }) => {
  const results = React.useMemo(() => {
    const entryMap = _.keyBy(data.entries, 'id');

    const resultMap = {};

    for (const category of data.categories) {
      for (const ticket of data.tickets) {
        if (!ticket.categories) {
          continue;
        }

        const vote = ticket.categories[category.id];

        if (!vote) {
          continue;
        }

        if (!resultMap[vote]) {
          resultMap[vote] = {};
        }

        const previousCount = resultMap[vote][category.id] || 0;
        resultMap[vote][category.id] = previousCount + 1;
      }
    }

    const results = [];
    for (const [entryId, categories] of Object.entries(resultMap)) {
      const entry = entryMap[entryId];
      results.push({
        ...entry,
        categories,
      });
    }

    return results;
  }, [data]);

  return (
    <Table responsive>
      <thead>
        <tr>
          <th>Nome</th>
          <th>Fantasia</th>
          {data.categories.map(category => (
            <th key={category.id}>{category.name}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {results.map(entry => (
          <Row key={entry.id} entry={entry} categories={data.categories} />
        ))}
      </tbody>
    </Table>
  );
};
