import React from 'react';
import Img from 'react-image';
import Loader from 'react-loaders';
import styled from 'styled-components';
import { prop } from 'styled-tools';

const Image = styled(Img)``;

const LoaderWrapper = styled.div`
  width: ${prop('width', 'auto')};
  height: ${prop('height', 'auto')};

  display: flex;
  align-items: center;
  justify-content: center;

  background: #aaa;
`;

const Loading = ({ width, height }) => (
  <LoaderWrapper width={width} height={height}>
    <Loader type="ball-pulse-sync" />
  </LoaderWrapper>
);

export default ({ width, height, ...props }) => {
  return (
    <Image
      loader={<Loading width={width} height={height} />}
      {...props}
      width={width}
      height={height}
    />
  );
};
