import React from 'react';
import { useBoolean } from 'react-use';
import Webcam from 'react-webcam';
import styled from 'styled-components';

const VIDEO_CONSTRAINTS = {
  width: 1280,
  height: 720,
};

const Wrapper = styled.div`
  width: 100%;
  height: 100%;

  flex: 1;
  align-self: stretch;

  position: relative;

  background: #000;
`;

const StyledWebcam = styled(Webcam)`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  width: 100%;
`;

const Content = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
`;

export default ({ webcamRef, onReady, children, ...props }) => {
  const [ready, setReady] = useBoolean(false);

  const onWebcamReady = () => {
    setReady(true);
    onReady();
  };

  return (
    <Wrapper {...props}>
      {ready}
      <StyledWebcam
        ref={webcamRef}
        audio={false}
        screenshotFormat="image/jpeg"
        onUserMedia={onWebcamReady}
        videoConstraints={VIDEO_CONSTRAINTS}
      />
      <Content>{children}</Content>
    </Wrapper>
  );
};
