import React from 'react';
import _ from 'lodash';
import tickets from '../../../services/tickets';
import List from './List';
import categories from '../../../services/categories';
import entries from '../../../services/entries';

export default () => {
  const [loading, setLoading] = React.useState(true);
  const [ticketList, setTicketList] = React.useState([]);
  const [categoryList, setCategoryList] = React.useState([]);
  const [entryMap, setEntryMap] = React.useState([]);

  const updateData = () => {
    categories
      .list()
      .then(categories => {
        setCategoryList(categories);
      })
      .then(() => entries.list())
      .then(entries => {
        const entryMap = _.keyBy(entries, 'id');
        setEntryMap(entryMap);
      })
      .then(() => tickets.list())
      .then(votes => {
        setTicketList(votes);
        setLoading(false);
      });
  };

  React.useEffect(() => updateData(), []);

  const onDelete = vote => {
    if (window.confirm(`Confirma remoção de "${vote.id}"?`)) {
      onSave({
        ...vote,
        deleted: true,
      });
    }
  };

  const onSave = ({ id, ...data }) => {
    setLoading(true);
    tickets.update(id, data).then(() => updateData());
  };

  const onGenerate = event => {
    event.preventDefault();

    setLoading(true);
    tickets.create(10).then(() => {
      updateData();
    });
  };

  if (loading) {
    return <div>Carregando...</div>;
  }

  return (
    <div>
      <button onClick={onGenerate}>Gerar 10 tickets</button>
      <List
        votes={ticketList}
        categories={categoryList}
        entryMap={entryMap}
        {...{ onDelete }}
      />
    </div>
  );
};
