import React from 'react';
import styled from 'styled-components';
import { Player, ControlBar } from 'video-react';
import Sound from 'react-sound';
import _ from 'lodash';
import remote from '../../../services/remote';

const Wrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  z-index: 10;

  /* opacity: 0; */

  /* animation: screamer 3s ease-out 1; */

  @keyframes screamer {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;

const StyledPlayer = styled(Player)`
  width: 100% !important;
  height: 100% !important;
  overflow: hidden !important;
  padding-top: 0 !important;

  & > * {
    display: none !important;
  }

  & > video {
    display: block !important;
    object-fit: cover;
    width: 100vw !important;
    height: 100vh !important;
  }
`;

const Overlay = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
`;

const videos = [
  '/assets/jumps/bear.mp4',
  '/assets/jumps/bunny.mp4',
  '/assets/jumps/galinha.mp4',
  '/assets/jumps/mask.mp4',
];

const screams = [
  '/assets/screams/Howard Dean Screaming-SoundBible.com-54426183.mp3',
  '/assets/screams/nmh_scream1.mp3',
  '/assets/screams/Scream And Die Fx-SoundBible.com-299479967.mp3',
  '/assets/screams/Screaming Female-SoundBible.com-414994458.mp3',
  '/assets/screams/Tortured Person Screaming-SoundBible.com-63498375.mp3',
  // '/assets/screams/woscream4.mp3',
];

export default ({ onDone }) => {
  const [screamLoaded, setScreamLoaded] = React.useState(false);

  const [videoFinished, setVideoFinished] = React.useState(false);
  const [screamFinished, setScreamFinished] = React.useState(false);
  const [entry, setEntry] = React.useState();

  React.useEffect(() => {
    const video = _.sample(videos);
    const scream = _.sample(screams);
    setEntry({ video, scream });
  }, []);

  const finished = videoFinished && screamFinished;
  React.useEffect(() => {
    if (finished) {
      onDone();
      remote.setSpooky(false);
    }
  }, [finished, onDone]);

  // useTimeoutFn(onDone, 1000);
  // const videoUrl = 'https://media.w3.org/2010/05/sintel/trailer_hd.mp4';

  if (!entry) {
    return null;
  }

  return (
    <Wrapper>
      <StyledPlayer
        muted
        autoPlay
        src={entry.video}
        onEnded={() => setVideoFinished(true)}
      >
        <ControlBar disableCompletely={true} />
      </StyledPlayer>
      <Sound
        autoLoad
        onLoad={() => setScreamLoaded(true)}
        url={entry.scream}
        playStatus={
          screamLoaded && !screamFinished
            ? Sound.status.PLAYING
            : Sound.status.STOPPED
        }
        onFinishedPlaying={() => setScreamFinished(true)}
        volume={100}
      />
      <Overlay />
    </Wrapper>
  );
};
