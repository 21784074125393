import React from 'react';
import styled from 'styled-components';
import QRCode from 'qrcode.react';

const Wrapper = styled.div`
  border: 0.05cm solid #ccc;
  width: 6.5cm;
  height: 9.89cm;

  padding: 0.8cm 0.6cm 0.6cm;

  display: flex;
  align-items: center;
  flex-direction: column;

  text-align: center;
`;

const Text1 = styled.div`
  margin-bottom: 0.4cm;
  font-size: 0.4cm;
`;

const Text2 = styled.div`
  margin-bottom: 0.04cm;
  font-size: 0.5cm;
  font-weight: bold;
`;

const TextCode = styled.div`
  font-size: 1cm;
  line-height: 1.2em;
  font-weight: 900;
`;

const StyledQRCode = styled(QRCode)`
  width: 5cm;
  margin: 0.4cm 0 0.8cm;
`;
const Link = styled.span`
  font-weight: bold;
`;

export default ({ code }) => {
  return (
    <Wrapper>
      <Text1>
        Use o QR Code ou acesse
        <br />
        <Link>boo.pushstart.com.br</Link>
      </Text1>
      <StyledQRCode
        value={`https://halloween-c6e43.firebaseapp.com/vote/${code}`}
      />
      <Text2>Seu código</Text2>
      <TextCode>{code}</TextCode>
    </Wrapper>
  );
};
