import React from 'react';
import styled from 'styled-components';
import { useTimeoutFn } from 'react-use';

const Flash = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  z-index: 10;

  animation: blinker 1s ease-out 1;

  @keyframes blinker {
    0% {
      background: #fff;
    }
    100% {
      background-color: transparent;
    }
  }
`;

export default ({ onDone }) => {
  useTimeoutFn(onDone, 1000);
  return <Flash />;
};
