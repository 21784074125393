import React from 'react';
import styled from 'styled-components';
import FirebaseImage from '../../components/FirebaseImage';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 48px;

  /* todo: tirar */
  width: 420px;
  height: 420px;
`;

const Frame = styled.div`
  background: white;
  border: 1px solid white;
  color: black;

  padding: 12px;

  margin-bottom: 24px;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ImageFrame = styled.div`
  margin-bottom: 12px;
  width: 370px;
  height: 278px;
`;

const Costume = styled.h3`
  font-weight: 900;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const Name = styled.h4`
  font-weight: bold;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const Hint = styled.div`
  padding: 0 32px;
  font-size: 20px;

  text-align: center;
  font-weight: bold;
`;

export default ({ entry }) => {
  return (
    <Wrapper>
      <Frame>
        <ImageFrame>
          <FirebaseImage path={entry.imagePath} width="100%" height="100%" />
        </ImageFrame>
        <Costume>{entry.costume}</Costume>
        <Name>{entry.name}</Name>
      </Frame>
      {entry.hint && (
        <React.Fragment>
          <Hint>Suspeito #{entry.hint.id}</Hint>
          <Hint>{entry.hint.text}</Hint>
        </React.Fragment>
      )}
    </Wrapper>
  );
};
