import { database } from './firebase';

function create() {
  const now = +new Date();

  const entries = database.ref('entries');
  const newEntry = entries.push({ createdAt: now });
  return newEntry.key;
}

function update(id, data) {
  const entry = database.ref(`entries/${id}`);
  return entry.update(data);
}

async function list() {
  const snapshot = await database.ref(`entries`).once('value');

  const values = snapshot.val();
  if (!values) {
    return [];
  }

  return Object.entries(values)
    .map(([id, entry]) => ({
      ...entry,
      id,
    }))
    .filter(entry => !entry.deleted);
}

async function listEligible() {
  const entries = await list();
  return entries.filter(entry => entry.finished);
}

export default {
  create,
  update,
  list,
  listEligible,
};
