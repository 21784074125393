import { faCheck, faRedoAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styled from 'styled-components';
import Button from '../../../components/Button';

const Wrapper = styled.div`
  position: absolute;
  bottom: 4rem;

  display: flex;
  flex-direction: row;
  align-self: center;

  color: #fff;

  font-weight: bold;
`;

const StyledButton = styled(Button)`
  margin: 0 48px;
`;

export default ({ onAccept, onReject }) => {
  const onClickReject = event => {
    event.preventDefault();
    onReject();
  };

  const onClickAccept = event => {
    event.preventDefault();
    onAccept();
  };

  return (
    <Wrapper>
      <StyledButton onClick={onClickReject}>
        <FontAwesomeIcon icon={faRedoAlt} />
        Refazer
      </StyledButton>
      <StyledButton color="green" onClick={onClickAccept}>
        <FontAwesomeIcon icon={faCheck} />
        Aceitar
      </StyledButton>
    </Wrapper>
  );
};
