import React from 'react';
import Form from './Form';
import Image from './Image';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-evenly;
  align-items: center;

  align-self: stretch;
  flex: 1;
`;

export default ({ stepData, onStepFinished }) => {
  const { image } = stepData;

  const onSave = ({ name, costume }) => {
    onStepFinished({
      ...stepData,
      name,
      costume,
    });
  };

  return (
    <Wrapper>
      <Image image={image} />
      <Form onSave={onSave} />
    </Wrapper>
  );
};
