import React from 'react';
import { useTimeoutFn } from 'react-use';
import styled from 'styled-components';
import Button from '../../../components/Button';
import SpookyIcon from '../../../components/SpookyIcon';
import palette from '../../../config/palette';
import entries from '../../../services/entries';

const Wrapper = styled.div`
  display: flex;

  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;

  align-self: stretch;
  flex: 1;
`;

const Title = styled.div`
  width: 400px;

  color: ${palette.lightPurple};

  font-size: 2em;
  line-height: 1.4em;
  text-align: center;

  font-weight: bold;
`;

const Logo = styled.img`
  width: 397px;
`;

const AUTO_RELOAD_TIME = 30 * 1000;

export default ({ stepData, onStepFinished }) => {
  const [ready, setReady] = React.useState(false);

  React.useEffect(() => {
    const { id, image, name, costume } = stepData;
    const data = {
      imagePath: image.path,
      name,
      costume,
      finished: true,
    };

    entries.update(id, data).then(() => {
      setReady(true);
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useTimeoutFn(() => {
    onStepFinished({});
  }, AUTO_RELOAD_TIME);

  const onClick = event => {
    if (ready) {
      onStepFinished({});
    }
    event.preventDefault();
  };

  return (
    <Wrapper>
      <Logo src={require('./assets/logo.png')} />
      <Title>Agora vá aterrorizar seus conhecidos de trabalho!</Title>
      <Button onClick={onClick}>
        <SpookyIcon />
        Reiniciar
      </Button>
    </Wrapper>
  );
};
