import {
  faBat,
  faBookDead,
  faCauldron,
  faCoffin,
  faGhost,
  faHatWitch,
  faHockeyMask,
  faJackOLantern,
  faScarecrow,
  faSkull,
  faSpider,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import React from 'react';

const icons = [
  faJackOLantern,
  faBat,
  faCoffin,
  faCauldron,
  faBookDead,
  faHatWitch,
  faGhost,
  faHockeyMask,
  faSkull,
  faSpider,
  faScarecrow,
];

export default () => {
  const [icon, setIcon] = React.useState();
  React.useEffect(() => {
    setIcon(_.sample(icons));
  }, [setIcon]);

  if (!icon) {
    return null;
  }

  return <FontAwesomeIcon icon={icon} />;
};
