import React from 'react';
import styled from 'styled-components';
import Button from '../../../components/Button';
import SpookyIcon from '../../../components/SpookyIcon';
import palette from '../../../config/palette';
import entries from '../../../services/entries';

const Wrapper = styled.div`
  display: flex;

  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;

  align-self: stretch;
  flex: 1;
`;

const Title = styled.div`
  width: 380px;

  color: ${palette.lightPurple};

  font-size: 32px;
  line-height: 1.4em;
  text-align: center;
  font-weight: bold;
`;

const Logo = styled.img`
  width: 684px;
`;

export default ({ onStepFinished }) => {
  const onClick = React.useCallback(
    event => {
      event.preventDefault();

      const id = entries.create();
      onStepFinished({ id });
    },
    [onStepFinished],
  );
  return (
    <Wrapper>
      <Logo src={require('./assets/logo.png')} />
      <Title>Aperte Start para cadastrar sua fantasia</Title>
      <Button onClick={onClick}>
        <SpookyIcon />
        Start
      </Button>
    </Wrapper>
  );
};
