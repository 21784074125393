import React from 'react';
import styled from 'styled-components';
import palette from '../../../config/palette';

const Wrapper = styled.div`
  display: flex;

  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;

  align-self: stretch;
  flex: 1;
`;

const Title = styled.div`
  color: ${palette.midnight};
  font-size: 2em;
`;

export default () => {
  return (
    <Wrapper>
      <Title>Cadastro finalizado!</Title>
    </Wrapper>
  );
};
