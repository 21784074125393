import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Route, Redirect, Switch } from 'react-router';
import Capture from './scenes/Capture';
import Manage from './scenes/Manage';
import Vote from './scenes/Vote';
import Print from './scenes/Print';
import Spooky from './scenes/Spooky';
import Posters from './scenes/Posters';

export default () => (
  <BrowserRouter>
    <Switch>
      <Route path="/capture" component={Capture} />
      <Route path="/manage" component={Manage} />
      <Route path="/vote/:code" component={Vote} />
      <Route path="/vote" component={Vote} />
      <Route path="/print" component={Print} />
      <Route path="/spooky" component={Spooky} />
      <Route path="/posters" component={Posters} />
      <Route path="/" exact render={() => <Redirect to="/vote" />} />
    </Switch>
  </BrowserRouter>
);
