import React from 'react';
import {
  Nav,
  Navbar,
  NavbarBrand,
  NavItem,
  NavLink,
  Container,
} from 'reactstrap';
import Router from './Router';

export default () => {
  return (
    <Container>
      <Navbar color="light" light expand="md">
        <NavbarBrand href="/">Halloween</NavbarBrand>
        <Nav className="ml-auto" navbar>
          <NavItem>
            <NavLink href="/manage/entries/">Participantes</NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="/manage/tickets/">Tickets</NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="/manage/results/">Resultados</NavLink>
          </NavItem>
        </Nav>
      </Navbar>
      <Router />
    </Container>
  );
};
