import { storage } from './firebase';
import uuidv4 from 'uuid/v4';

function uploadUnique(data) {
  const key = uuidv4();
  const ref = storage.ref(`photos/${key}`);
  ref.putString(data, 'data_url');
  return ref.fullPath;
}

function getSrc(path) {
  return storage.ref(path).getDownloadURL();
}

export default {
  uploadUnique,
  getSrc,
};
