import React from 'react';
import { useBoolean, useInterval } from 'react-use';
import styled from 'styled-components';
import images from '../../../services/images';
import Buttons from './Buttons';
import Camera from './Camera';
import Counter from './Counter';
import Overlay from './Overlay';
import cropImage from './cropImage';
import Flash from './Flash';
import Scare from './Scare';
import Instructions from './Instructions';

const ImageBackground = styled.img`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
`;

const COUNTER_INITIAL = 13;
const COUNTER_INTERVAL_MS = 1000;

export default ({ config, stepData, onStepFinished }) => {
  const webcamRef = React.useRef(null);

  const [ready, setReady] = useBoolean(false);
  const [flashed, setFlashed] = React.useState(false);
  const [counter, setCounter] = React.useState(COUNTER_INITIAL);
  const [imageData, setImageData] = React.useState();

  const timerFinished = counter <= 0;
  const isRunning = ready && !timerFinished;

  const onAccept = () => {
    onStepFinished({
      ...stepData,
      image: imageData,
    });
  };

  const onReject = () => {
    setCounter(COUNTER_INITIAL);
    setImageData(undefined);
    setFlashed(false);
  };

  const onFlash = () => {
    setFlashed(true);
  };

  useInterval(
    () => {
      const nextCounter = counter - 1;
      setCounter(nextCounter);

      if (nextCounter <= 0) {
        if (config.spooky) {
          setTimeout(() => {
            const imageSrc = webcamRef.current.getScreenshot();
            cropImage(imageSrc).then(croppedSrc => {
              const path = images.uploadUnique(croppedSrc);
              setImageData({ imageSrc, croppedSrc, path });
            });
          }, 1000);
        } else {
          const imageSrc = webcamRef.current.getScreenshot();
          cropImage(imageSrc).then(croppedSrc => {
            const path = images.uploadUnique(croppedSrc);
            setImageData({ imageSrc, croppedSrc, path });
          });
        }
      }
    },
    isRunning ? COUNTER_INTERVAL_MS : null,
  );

  const FlashComponent = config.spooky ? Scare : Flash;

  const hasCapture = !!imageData;

  return (
    <Camera webcamRef={webcamRef} onReady={() => setReady(true)}>
      <Overlay>
        {!hasCapture && isRunning && <Counter current={counter} />}
        {hasCapture && <Buttons {...{ onAccept, onReject }} />}
        {!hasCapture && isRunning && <Instructions />}
      </Overlay>
      {hasCapture && <ImageBackground src={imageData.imageSrc} />}
      {timerFinished && !flashed && <FlashComponent onDone={onFlash} />}
    </Camera>
  );
};
