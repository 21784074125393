import { database } from './firebase';

function randomCode() {
  const n = Math.floor(Math.random() * 1000000);
  return String(n).padStart(6, '0');
}

function create(amount) {
  const tickets = database.ref('tickets');

  const codes = [];
  for (let i = 0; i < amount; ++i) {
    const code = randomCode();
    tickets.child(code).set({ valid: true });
  }

  return Promise.resolve(codes);
}

async function list() {
  const snapshot = await database
    .ref(`tickets`)
    .orderByKey()
    .once('value');

  const values = snapshot.val();
  if (!values) {
    return [];
  }

  return Object.entries(values)
    .map(([id, entry]) => ({
      ...entry,
      id,
    }))
    .filter(entry => !entry.deleted);
}

function update(id, data) {
  const ref = database.ref(`tickets/${id}`);
  return ref.update(data);
}

async function getById(id) {
  const snapshot = await database.ref(`tickets/${id}`).once('value');

  const value = snapshot.val();
  if (!value) {
    throw new Error('Ticket not found!');
  }

  return { id, ...value };
}

export default {
  create,
  update,
  list,
  getById,
};
