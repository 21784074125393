import React from 'react';
import entries from '../../services/entries';
import styled from 'styled-components';
import Poster from './Poster';
import { createGlobalStyle } from 'styled-components';
import AliceCarousel from 'react-alice-carousel';

import 'react-alice-carousel/lib/alice-carousel.css';
import { useInterval } from 'react-use';

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Roboto+Slab:700&display=swap');

  .alice-carousel {
    margin-top: 0 !important;
  }

  .alice-carousel__wrapper {
    height: 580px !important;
  }
`;

const Wrapper = styled.div`
  font-family: 'Roboto Slab', serif;

  background: #000;

  width: 100vw;
  height: 100vh;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Entries = styled.div`
  flex: 1;

  width: 1830px;

  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: flex-start;

  color: white;
`;

const Logo = styled.img`
  margin: 48px 0 140px;
`;

export default () => {
  const [entryList, setEntryList] = React.useState([]);

  const updateData = () => {
    entries.list().then(entryList => {
      setEntryList(entryList.filter(e => e.finished));
    });
  };

  React.useEffect(() => updateData(), []);

  useInterval(() => updateData(), 5 * 60 * 1000);

  const items = entryList.map(entry => <Poster entry={entry} key={entry.id} />);

  return (
    <Wrapper>
      <GlobalStyle />
      <Logo src={require('./assets/title.png')} />
      <Entries>
        <AliceCarousel
          items={items}
          infinite
          responsive={{
            0: {
              items: 4,
            },
          }}
          dotsDisabled
          buttonsDisabled
          autoPlay
          autoPlayInterval={7000}
        />
      </Entries>
    </Wrapper>
  );
};
