import React from 'react';
import styled from 'styled-components';
import BackgroundMusic from '../../components/BackgroundMusic';
import palette from '../../config/palette';
import remote from '../../services/remote';
import Capture from './Capture';
import Finished from './Finished';
import Idle from './Idle';
import Input from './Input';
import Ready from './Ready';

const steps = [
  //
  Idle,
  Capture,
  Input,
  Ready,
];

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;

  position: relative;
  display: flex;
  flex-direction: column;

  background: ${palette.darkPurple};

  & * {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Opera and Firefox */
  }
`;

export default () => {
  const [stepData, setStepData] = React.useState({});
  const [config, setConfig] = React.useState({});

  const [stepIndex, setStepIndex] = React.useState(0);
  const onStepFinished = React.useCallback(
    data => {
      setStepData(data);
      setStepIndex((stepIndex + 1) % steps.length);
    },
    [stepIndex, setStepIndex, setStepData],
  );

  React.useEffect(() => {
    return remote.watchConfig(snapshot => {
      const value = snapshot.val();
      setConfig(value);
    });
  }, []);

  let Step = steps[stepIndex];

  if (config.finished) {
    Step = Finished;
  }

  return (
    <Wrapper>
      <BackgroundMusic />
      <Step {...{ config, stepData, onStepFinished }} />
    </Wrapper>
  );
};
