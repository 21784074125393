import React from 'react';
import images from '../services/images';
import Image from './Image';

export default ({ path, component = Image, ...props }) => {
  const [src, setSrc] = React.useState([]);

  React.useEffect(() => {
    images.getSrc(path).then(src => {
      setSrc(src);
    });
  }, [path]);

  return <Image src={src} {...props} />;
};
