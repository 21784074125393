export default {
  midnight: '#313',
  eggplant: '#525',
  magenta: '#936',
  amber: '#D65',
  gold: '#FA6',
  white: '#FFF',
  purple: '#7C3D8E',
  darkPurple: '#321133',
  lightPurple: '#C48AC4',
  lighOrange: '#FFB472',
  orange: '#FF9233',
  darkOrange: '#AF580B',
  darkerOrange: '#6E340E',
  lighGreen: '#B0FFAB',
  green: '#79EC71',
  darkGreen: '#379B76',
  darkerGreen: '#1F6B4F',
};
