import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styled from 'styled-components';
import Button from '../../../components/Button';
import palette from '../../../config/palette';

const Wrapper = styled.div`
  background: ${palette.darkPurple};
  color: #000;

  display: flex;
  justify-content: center;

  padding: 12px 12px 16px;

  box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.6);

  z-index: 10;
`;

export default ({ onConfirm, disabled }) => {
  return (
    <Wrapper>
      <Button onClick={onConfirm} size={24} disabled={disabled}>
        <FontAwesomeIcon icon={faCheck} />
        Confirmar
      </Button>
    </Wrapper>
  );
};
