import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Form, FormGroup, Input, Label } from 'reactstrap';
import styled from 'styled-components';
import Button from '../../../components/Button';
import palette from '../../../config/palette';

const StyledForm = styled(Form)`
  flex: 1;
  padding: 0 2.4rem;
`;

const StyledLabel = styled(Label)`
  font-size: 30px;
  color: ${palette.orange};

  line-height: 1.2em;
  margin-bottom: 0;
`;

const StyledInput = styled(Input)`
  && {
    height: 1.2em;
    padding: 0;

    border: none;
    border-bottom: 2px solid ${palette.orange};

    color: ${palette.white};
    background: none;

    font-size: 48px;
    font-weight: bold;

    outline: none;
    :focus {
      outline-style: none;
      box-shadow: none;

      color: ${palette.white};
      background: none;

      border: none;
      border-bottom: 2px solid ${palette.orange};
    }
  }
`;

const StyledFormGroup = styled(FormGroup)`
  &&& {
    margin-bottom: 24px;
  }
`;

const Spacer = styled.div`
  height: 12px;
`;

export default ({ onSave }) => {
  const [name, setName] = React.useState('');
  const [costume, setCostume] = React.useState('');

  const onNameChange = event => {
    const name = String(event.target.value);
    setName(name);
  };

  const onCostumeChange = event => {
    const costume = String(event.target.value);
    setCostume(costume);
  };

  const onSubmit = event => {
    onSave({ name, costume });
    event.preventDefault();
  };

  const isComplete = name.length > 3 && costume.length > 3;

  return (
    <StyledForm onSubmitCapture={onSubmit}>
      <StyledFormGroup>
        <StyledLabel for="name">Seu nome</StyledLabel>
        <StyledInput
          type="name"
          name="name"
          id="name"
          value={name}
          onChange={onNameChange}
          autocomplete="off"
        />
      </StyledFormGroup>
      <StyledFormGroup>
        <StyledLabel for="costume">Nome da sua fantasia</StyledLabel>
        <StyledInput
          type="costume"
          name="costume"
          id="costume"
          value={costume}
          onChange={onCostumeChange}
          autocomplete="off"
        />
      </StyledFormGroup>
      <Spacer />
      <Button type="submit" color="green" disabled={!isComplete}>
        <FontAwesomeIcon icon={faCheck} />
        Concluir
      </Button>
    </StyledForm>
  );
};
