import React from 'react';
import categories from '../../../services/categories';
import entries from '../../../services/entries';
import tickets from '../../../services/tickets';
import Charts from './Charts';
import Table from './Table';

export default () => {
  const [loading, setLoading] = React.useState(true);
  const [data, setData] = React.useState(undefined);

  const updateVotes = async () => {
    const [ticketList, entryList, categoryList] = await Promise.all([
      tickets.list(),
      entries.list(),
      categories.list(),
    ]);

    setData({
      tickets: ticketList,
      entries: entryList,
      categories: categoryList,
    });
    setLoading(false);
  };

  React.useEffect(() => updateVotes(), []);

  if (loading) {
    return <div>Carregando...</div>;
  }

  return (
    <div>
      <Charts data={data} />
      <Table data={data} />
    </div>
  );
};
