import React from 'react';
import styled from 'styled-components';
import palette from '../../../config/palette';

const Wrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  display: flex;
  flex-direction: row;
  align-items: stretch;

  z-index: 1;
`;

const Cover = styled.div`
  flex: 1;

  background: ${palette.darkPurple};
  opacity: 0.9;
`;

const Content = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;

  width: 133.333vh;
`;

export default ({ webcamRef, onReady, children, ...props }) => {
  return (
    <Wrapper>
      <Cover />
      <Content {...props}>{children}</Content>
      <Cover />
    </Wrapper>
  );
};
